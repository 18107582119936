import { Providers } from "./src/components/Providers"
import { Layout } from "./src/components/Layout"
import { Script } from "gatsby"

/** @type {import('gatsby').GatsbySSR['wrapRootElement']} */
export const wrapRootElement = ({ element }) => <Providers>{element}</Providers>

/** @type {import('gatsby').GatsbySSR['wrapPageElement']} */
export const wrapPageElement = ({ element, props }) => {
	if (props.location.pathname.includes("_cms-guide")) return element

	return (
		<>
			<Layout>{element}</Layout>
			<Script src="/haku-widget.js" />
		</>
	)
}

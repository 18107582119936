import { Link, graphql, useStaticQuery } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import clsx from "clsx"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { useEffect, useRef, useState } from "react"
import { useLocation, globalHistory } from "@gatsbyjs/reach-router"

import { FacebookIcon } from "./FacebookIcon"
import { TwitterIcon } from "./TwitterIcon"
import { InstagramIcon } from "./InstagramIcon"
import logoURL from "../assets/logo-mark.svg"
import { SponsorBanner } from "./SponsorBanner"

import * as styles from "./Footer.module.css"

import type { FooterDataQuery } from "../graphql.gen"

function useFooterData() {
	const staticData = useStaticQuery<FooterDataQuery>(graphql`
		query FooterData {
			prismicSettings {
				_previewable
				data {
					navigation_menu {
						...NavigationMenuData
					}
					facebook_url
					twitter_url
					instagram_url
					message {
						text
					}
					privacy_policy {
						url
					}
					show_banner
				}
			}
		}
	`)
	const { data: mergedData } = useMergePrismicPreviewData(staticData)

	const data = mergedData.prismicSettings?.data
	const menu = data?.navigation_menu?.document
	if (menu?.__typename !== "PrismicNavigation") {
		throw new Error(
			"Did not receive a PrismicNavigation when we queried for it.",
		)
	}

	return {
		navMenu: menu.data.body.map((item) => ({
			text: item.primary.link_text,
			href: item.primary.link_url?.url,
		})),
		facebookUrl: data?.facebook_url,
		twitterUrl: data?.twitter_url,
		instagramUrl: data?.instagram_url,
		message: data?.message?.text,
		privacyPolicy: data?.privacy_policy?.url,
		showBanner: data?.show_banner,
	}
}

interface FooterIconProps {
	href: string
	icon: (props: React.ComponentPropsWithoutRef<"svg">) => JSX.Element
	className?: string
}
const FooterIcon = ({ className, href, icon: Icon }: FooterIconProps) => {
	return (
		<PrismicLink href={href} className={clsx(className)}>
			<Icon />
		</PrismicLink>
	)
}

export const Footer = () => {
	const data = useFooterData()
	const footerRef = useRef(null)
	const initialLocation = useLocation()
	const [showSponsors, setShowSponsors] = useState(false)

	useEffect(() => {
		window.addEventListener("resize", setMainOffset)
		setShowSponsors(initialLocation.pathname === "/")

		globalHistory.listen(({ action, location }) => {
			if (action === "PUSH") {
				setShowSponsors(location.pathname === "/")
			}
		})

		return () => {
			window.removeEventListener("resize", setMainOffset)
		}
	}, [])

	useEffect(() => {
		setMainOffset()
	}, [showSponsors])

	const setMainOffset = () => {
		const footerHeight = footerRef?.current?.offsetHeight + 1

		if (footerHeight) {
			const mainElement = document.querySelector("main")
			if (mainElement) {
				mainElement.style.paddingBottom = `${footerHeight}px`
			}
		}
	}

	return (
		<footer className={styles.footer} ref={footerRef}>
			{data.showBanner && showSponsors && <SponsorBanner />}

			<div className={styles.footerContainer}>
				<div className={styles.footerTop}>
					<nav className={styles.footerNavigation}>
						{data.navMenu.map((item) => (
							<PrismicLink key={item.text} href={item.href}>
								{item.text}
							</PrismicLink>
						))}

						<div className={styles.widgetContainer}>
							<p className={styles.widgetHeading}>Stay Connected</p>
							<div
								suppressHydrationWarning
								className={clsx(styles.widget, "haku-widget-target")}
								data-url="https://widget.hakuapp.com/v2/community_lists"
								data-api-key="SqphbNCX6i5dPqXYOI5kN5Kmeu4zsPIg3oeDRdb9"
								data-widget-type="list_signup"
								data-audience="4077daa255e6599b9237"
								data-short-form="true"
								data-layout="inline"
								data-background-color="021d45"
								data-font-family='"Byker", system-ui'
								data-text-color="fff"
							/>
						</div>

						<div className={styles.footerSocial}>
							{data.facebookUrl && (
								<FooterIcon icon={FacebookIcon} href={data.facebookUrl} />
							)}

							{data.twitterUrl && (
								<FooterIcon icon={TwitterIcon} href={data.twitterUrl} />
							)}

							{data.instagramUrl && (
								<FooterIcon icon={InstagramIcon} href={data.instagramUrl} />
							)}
						</div>
					</nav>

					<div className={styles.logoContainer}>
						<Link to="/" className={styles.footerLogo}>
							<img
								src={logoURL}
								alt="Rush to Crush Cancer"
								loading="eager"
								decoding="async"
							/>
						</Link>

						{data.message && <p className={styles.message}>{data.message}</p>}
					</div>
				</div>

				<div className={styles.footerCredits}>
					<div className={styles.footerCopyright}>
						<p>{`© ${new Date(Date.now())
							.getFullYear()
							.toString()} Rush to Crush Cancer`}</p>

						{data.privacyPolicy && (
							<a href={data.privacyPolicy}>Privacy Policy</a>
						)}
					</div>
					<p>
						Website design and development by{" "}
						<a
							href="https://www.walltowall.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							Wall-to-Wall Studios
						</a>
						. This site meets WCAG 2.1 Web Accessibility Standards.
					</p>
				</div>
			</div>
		</footer>
	)
}
